import React from "react";
import "./../styles/global.scss";
import NavbarCustom from "./../components/NavbarCustom";
import IndexPage from "./index";
import FaqPage from "./faq";
import ContactPage from "./contact";
import WaitlistPage from "./waitlist";
import { Switch, Route, Router } from "./../util/router.js";
import NotFoundPage from "./not-found.js";
import Footer from "./../components/Footer";
import "./../util/analytics.js";

function App(props) {
  return (
    <Router>
      <>
        <NavbarCustom bg="white" variant="light" expand="md" logo="logo.svg" />

        <Switch>
          <Route exact path="/" component={IndexPage} />

          <Route exact path="/faq" component={FaqPage} />

          <Route exact path="/contact" component={ContactPage} />

          <Route exact path="/waitlist" component={WaitlistPage} />

          <Route component={NotFoundPage} />
        </Switch>

        <Footer
          bg="light"
          textColor="dark"
          size="sm"
          bgImage=""
          bgImageOpacity={1}
          description="The social calendar we all need"
          copyright="© 2023 TimeMixer"
          logo="logo.svg"
        />
      </>
    </Router>
  );
}

export default App;
