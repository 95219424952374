import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Faq from "./Faq";

function FaqSection(props) {
  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={2}
          spaced={true}
          className="text-center"
        />
        <Faq
          items={[
            {
              question: "When can I get TimeMixer?",
              answer:
                "We'll send you an invite just as soon as TimeMixer lis ready for you. We are working hard to make sure all the local things that make TimeMixer so awesome are ready for you.",
            },
            {
              question: "What happens when I signup?",
              answer:
                "We'll send you an invite to let you know that you can now signup in TimeMixer using the same email you requested an invite with. You will be able to download the app for your phone or use the web app on your computer.",
            },
            {
              question: "How do I invite my friends and family?",
              answer:
                "When you signup you can also enter your friends email addresses. We'll send them an invite at the same time you you get one.",
            },
          ]}
        />
      </Container>
    </Section>
  );
}

export default FaqSection;
