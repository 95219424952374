import React from "react";
import HeroSection from "./../components/HeroSection";
import { useRouter } from "./../util/router.js";

function IndexPage(props) {
  const router = useRouter();

  return (
    <HeroSection
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      title="Never miss anything again"
      subtitle="TimeMixer will soon be launching a new way to effortlessly keep on top of your schedule and keep friends and family in the loop. Interested? Sign up to get an invite as soon as TimeMixer is ready for you."
      buttonText="Sign up for an invite"
      buttonColor="primary"
      image="undraw_events_2p66.svg"
      buttonOnClick={() => {
        // Navigate to contact page
        router.push("/waitlist");
      }}
    />
  );
}

export default IndexPage;
