import React from "react";
import WaitlistSection from "./../components/WaitlistSection";

function WaitlistPage(props) {
  return (
    <WaitlistSection
      bg="white"
      textColor="dark"
      size="md"
      bgImage=""
      bgImageOpacity={1}
      title="Join the wait list"
      subtitle="Get an invite when TimeMixer is ready for you"
      buttonText="Signup"
      buttonColor="primary"
      showNameField={true}
    />
  );
}

export default WaitlistPage;
